import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Skeleton as MuiSekeleton, Stack} from '@mui/material';

export const Skeleton: FC = () => {
    return <Stack direction="column" gap={1.5}>

        <MuiSekeleton width="100%" height={200} variant="rounded"/>

        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
            <Stack width="80%">
                <Text
                    center
                    semibold
                    variant="h4"
                >
                    <MuiSekeleton variant="text" width="40%"/>
                </Text>


                <Text
                    center
                    semibold
                    variant="body3"
                >
                    <MuiSekeleton variant="text" width="60%"/>
                    <MuiSekeleton variant="text" width="70%"/>
                </Text>
            </Stack>

            <MuiSekeleton width={48} height={48} variant="circular"/>
        </Stack>

    </Stack>;
};
