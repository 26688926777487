import {FC} from 'react';
import {Box, Container} from '@mui/material';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    Data
} from 'components/page/realEstate/common/advertisements/searchResult/data/Data';
import {
    NoData
} from 'components/page/realEstate/common/advertisements/searchResult/NoData';
import {LIMIT} from 'components/page/realEstate/common/advertisements/constants/limit';

interface ISearchResult {
    noData: boolean;
    isAgent?: boolean;
    isLoading: boolean;
    totalPages: number;
    currentPage: number;
    advertisements: IAdvertisement[];
    onPageChange: (newPage: number) => void;
}

export const SearchResult: FC<ISearchResult> = ({
    noData,
    isAgent,
    isLoading,
    totalPages,
    currentPage,
    onPageChange,
    advertisements,
}) => {
    return (
        <Container>
            <Box mb={10}>
                {noData
                    ? <NoData isAgent={isAgent}/>
                    : <Data
                        limit={LIMIT}
                        isLoading={isLoading}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        advertisements={advertisements}
                    />
                }
            </Box>
        </Container>
    );
};
