import {FC} from 'react';
import {Stack} from '@mui/material';
import {Paginator} from 'modules/theme/components/paginator/Paginator';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    Loading
} from 'components/page/realEstate/common/advertisements/searchResult/data/Loading';
import {
    List
} from 'components/page/realEstate/common/advertisements/searchResult/listing/List';

interface IData {
    limit: number;
    isLoading: boolean;
    totalPages: number;
    currentPage: number;
    advertisements: IAdvertisement[];
    onPageChange: (newPage: number) => void;
}

export const Data: FC<IData> = ({advertisements, limit, currentPage, isLoading, totalPages, onPageChange}) => {

    return <Stack direction="column" gap={5}>

        <Stack direction="column" gap={5}>
            {isLoading
                ? <Loading/>
                : <List advertisements={advertisements} currentPage={currentPage} limit={limit}/>
            }
        </Stack>
        {!isLoading && <Paginator
            showScrollToTopBtn
            isDisabled={isLoading}
            onChange={onPageChange}
            currentPage={currentPage}
            totalPageCount={totalPages}
        />}
    </Stack>;
};
