import {FC} from 'react';
import {Box} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';
import NoDataImg from 'components/page/realEstate/common/advertisements/searchResult/listing/noListingData.svg';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface Props {
    isAgent?: boolean
}

export const NoData: FC<Props> = ({ isAgent }) => {
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center"
            mt={{ xs: 5, md: 12.5 }} mb={{ xs: 8, md: 16 }}
        >
            <Box mb={2}>
                <Mobile>
                    <Svg component={NoDataImg} width={110} height={110}/>
                </Mobile>
                <Desktop>
                    <Svg component={NoDataImg} width={128} height={128}/>
                </Desktop>
            </Box>

            <Box mb={1}>
                <Text variant="h5" semibold>
                    Žiadne ponuky
                </Text>
            </Box>

            <Box>
                <Text variant="body3">
                    {isAgent ? 'Realitný maklér' : 'Realitná kancelária'} nemá aktuálne v ponuke žiadnu nehnuteľnosť.
                </Text>
            </Box>
        </Box>
    );
};
